import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("userAuth")
    ? JSON.parse(window.localStorage.getItem("userAuth"))
    : {}

const setUser = user =>
  window.localStorage.setItem("userAuth", JSON.stringify(user))

export const handleLogin = data => {
  const { email, password } = data
  if (email === "user@enefevo.mx" && password === "password") {
    return setUser({
      username: "usuario",
      name: "User",
      email: "user@enefevo.mx",
    })
  }
  return false

  //   return axios
  //     .post("https://enefevo.mx/api/auth/login", data)
  //     .then(result => {
  //       console.log("result-login", result)
  //       setUser(data)
  //       return result
  //     })
  //     .catch(error => {
  //       console.error(error)
  //       throw error
  //     })
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}
