// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
  {
    name: "",
    label: "Acerca de",
    items: [
      { name: "#que-es-section", label: "Qué es enefevo" },
      { name: "#proceso-section", label: "Proceso" },
      { name: "#experiencia-usuario-section", label: "Experiencia de usuario" },
    ],
  },
  {
    name: "",
    label: "Intégrate",
    items: [
      { name: "#como-integrarte-section", label: "Cómo integrarte" },
      { name: "#beneficios-section", label: "Beneficios" },
    ],
  },
  {
    name: "#contacto-section",
    label: "Contacto",
    // isExternal: true,
  },
]
export default menuItems
