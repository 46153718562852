import logo from "~image/logo/logo.svg"
// import logo from "~image/logo/logo-white.svg"

const headerDefaultConfig = {
  headerClasses:
    "site-header site-header--menu-center light-header position-relative",
  containerFluid: true,
  darkLogo: true,
  defaultLogo: logo,
}
export default headerDefaultConfig
