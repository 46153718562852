// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-forgot-js": () => import("./../../../src/pages/account/forgot.js" /* webpackChunkName: "component---src-pages-account-forgot-js" */),
  "component---src-pages-account-sign-in-js": () => import("./../../../src/pages/account/sign-in.js" /* webpackChunkName: "component---src-pages-account-sign-in-js" */),
  "component---src-pages-account-sign-up-js": () => import("./../../../src/pages/account/sign-up.js" /* webpackChunkName: "component---src-pages-account-sign-up-js" */),
  "component---src-pages-app-profile-js": () => import("./../../../src/pages/app/profile.js" /* webpackChunkName: "component---src-pages-app-profile-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

